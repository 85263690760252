#gcd-input-search::after {
  content: url(../../assets/images/search.svg);
}

#gcd-input-search {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  top: 2px;
  left: -33px;
  position: relative;

}

.terkepek {
  margin-bottom: 0px;
  li {
    font-size: 12px;
  }

  label {
    font-size: 12px !important;
  }
}

.map-setting {
  label {
    button {
      background-color: transparent;
      font-size: 12px;
      border: 0px solid white;
      outline: none !important;
      font-weight: bold;
    }
  }

  .accordion label {
    margin-bottom: 0px;
  }

  .listitems {
    background-color: transparent;
    font-size: 12px;
    border: 0px solid white;
    outline: none !important;
    margin-left: 7px;
    line-height: 21px;
  }

  ul {
    padding-left: 10px !important;
    list-style: none !important;
  }
}

.page-content.full {
  padding-top: 78px;

  .browse-layout {
    overflow: hidden;
    height: 100%;
  }

  &.map {
    margin-left: 55px;
    overflow: hidden;
    padding-top: 76px;
    height: 100vh;

    @media screen and (max-width: 767px) {
      margin-left: 0;
      padding-top: 120px;
    }

    .project-sidebar-wrapper.card {
      margin-bottom: 0;
      height: auto !important;
      bottom: 0;
    }
  }

  @media screen and (max-width: 767px) {
    padding-top: 120px;
  }

  .container-fluid {
    height: 100%;

    @media screen and (min-width: 767px) {
      padding-top: 0px;
    }

    .row {
      height: 100%;
    }
  }
}

.filter-reset {
  position: absolute;
  right: 0px;
  height: 27px !important;
  line-height: 12px !important;
  font-weight: normal !important;
  outline: none !important;
}

.inner-map {
  height: 100%;
  position: relative;
  top: 0;
  right: 0;
  z-index: 1;
  margin-right: 260px;

  .leaflet-container {
    height: 100%;
    width: 100%;
    position: relative;
  }

  .openlayers-map {
    height: 100%;
    width: 100%;
    position: relative;
  }
}

.filters-layer {
  position: relative;
  top: 0px;
  left: 0px;
  width: 100px !important;
  max-width: 100px;
  z-index: 2;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 0px;

  .controls {
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
    flex-direction: row;

    .range-slider__tooltip {
      opacity: 1 !important;
    }

    > div {
      position: relative;
      margin-right: 7.5px;
      margin-bottom: 5px;
      top: 0px;
      left: 0px;
      .card {
        margin-top: 15px;
      }

      form {
        margin-top: 28px;
      }

      &.filters {
        .card {
          margin-left: 12px;
          .flex {
            width: 100%;
            display: flex;
            height: 300px;

            h5 {
              font-size: 13px;
              font-weight: normal;
            }
          }
        }

        .tab-content {
          margin-top: 7.5px;
          overflow: auto;
        }

        .nav-tabs {
          line-height: 10px !important;

          a {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            font-size: 14px;
            color: #444444;
          }

          .nav-link {
            background-color: #f3f3f3;
            border: 1px solid white;
            outline: none;

            &.active {
              border: 1px solid #f1f1f1;
              background-color: white;
            }
          }
        }

        .form-label {
          font-size: 12px !important;
        }

        .form-group {
          margin-bottom: 7.5px;
          margin-top: 0px;

          input {
            height: 30px;
            font-size: 11px;
            font-weight: normal;
          }

          .form-check-input {
            height: auto;
            top: 2px;
          }

          select {
            height: 27px;
            font-size: 11px;
          }

          .range-slider__wrap {
            font-size: 11px !important;
          }

          .range-controller .min,
          .range-controller .max {
            font-size: 11px !important;
          }
        }

        .vertical-scroll {
          flex-wrap: nowrap;
        }
      }
    }

    .draw-button,
    .clear-button,
    .sizer-button {
      margin-left: auto;
    }
  }

  .col-md-12 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .chevron {
    margin-left: auto !important;
    margin-right: 0px !important;
    font-size: 17px !important;
  }

  justify-content: space-between;

  .map-setting:last-child {
    padding-right: 0px;
  }
}

//DETAILS VIEW

.project-details-map {
  margin-bottom: 10px;
  border-bottom: 0.5px solid #dbdbdb;
  font-size: 14px;

  p {
    padding-left: 10px;
    margin: 0;
    padding-right: 10px;
    padding-top: 2px;
  }
}

.ol-tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  font-size: 12px;
}
.ol-tooltip-measure {
  opacity: 1;
  font-weight: bold;
}
.ol-tooltip-static {
  background-color: #ffcc33;
  color: black;
  border: 1px solid white;
}
.ol-tooltip-measure:before,
.ol-tooltip-static:before {
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: "";
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}
.ol-tooltip-static:before {
  border-top-color: #ffcc33;
}

.map-type-switch {
  width: 80% !important;
  margin: 8%;
}

// Map update
.map {
  .mapview {
    position: relative;
    //height: calc(100% - 10px);
    margin-top: 0px;

    .project-sidebar {
      position: static;

      .project-sidebar-wrapper {
        margin-top: 0;
        top: 0;
        border: 1px solid #f4f4f4;
        border-radius: 0 0 0 0 !important;
        position: absolute;
        width: 90%;
        max-width: 305px;

        &.closed{
          width: 45px;
        }
      }
    }
  }

  .filter-container {
    background-color: transparent;
    padding-top: 27px;
    padding-right: 0px;
    padding-left: 0;

    @media screen and (min-width: 767px) {
      padding-top: 0;
    }
  }

  .filters-layer {
    margin-top: 15px;
    margin-bottom: 20px;
    padding-left: 0 !important;
    position: relative;
    top: 17px;
    left: 45px;
    height: 0;
    margin: 0;

    .controls {
      .map-setting {
        margin: 0;
        padding: 0;
        box-shadow: none;
        height: 42px;

        .map-button {
          border-left: 0 !important;

          .icon {
            position: relative;
            top: 2px;
            left: -2px;
          }

          .card {
            margin-top: 0 !important;
            top: 32px;
            left: -20px;
            border: 1px solid #f4f4f4;
            border-radius: 5px;

            .card-header {
              border-bottom: 1px solid #f4f4f4;
              border-radius: 5px 5px 0 0;
            }
          }

          .clickable {
            height: 42px;
            overflow: hidden;
          }
        }
      }

      .map-button {
        display: inline-block;
        border: 0;
        border-radius: 0 !important;
        border-top: 1px solid #f4f4f4;
        border-bottom: 1px solid #f4f4f4;
        box-shadow: none;
        margin-right: 0;
        border-left: 0;
        margin-bottom: 0;

        &.search {
          position: relative;

          &.map-button {
            i {
              position: relative;
              top: 2px;
            }
          }

          &:after {
            position: absolute;
            content: "";
            width: 1px;
            background-color: #f4f4f4;
            top: 7px;
            bottom: 7px;
            right: 0;
          }

          form {
            margin-top: 32px;
            left: -20px;
            border: 1px solid #f4f4f4;
            border-radius: 5px;

            input {
              background-color: transparent;
              box-shadow: none;
            }
          }
        }

        &:first-child {
          border-left: 1px solid #f4f4f4;
          border-radius: 20px 0 0 20px !important;
          padding-left: 20px;
          width: 50px !important;
        }

        &:last-child {
          border-right: 1px solid #f4f4f4;
          border-radius: 0 20px 20px 0 !important;
          padding-right: 20px;
          width: 50px !important;
        }
      }
    }
  }

  .map-button:hover{
    background-color: #e3e3e3 !important;
  }

  .map-buttons {
    position: absolute;
    //right: 40px;
    left: 60px;
    width: 380px;
    top: 17px;
    z-index: 10;
    text-align: left;
    padding-right: 0;

    @media screen and (max-width: 767px) {
      display: none;
    }

    > div {
      display: inline-block;
      border: 0;
      border-radius: 0 !important;
      position: relative;

      i {
        position: relative;
        left: -2px;
      }

      &:first-child {
        border-radius: 20px 0 0 20px !important;
        padding-left: 20px;
        width: 50px !important;
      }

      &:last-child {
        border-radius: 0 20px 20px 0 !important;
        width: 40px !important;
      }
    }
  }
  .save-button {
    position: absolute;
    left: 60px;
    top: 65px;
    z-index: 10;
    text-align: left;
    .sv-button {
      display: inline-block;
      padding: 10px;
      color: black;
      font-size: 15px;
      text-decoration: none;
      text-align: center;
      height: 42px !important;
      width: 80px;
      background-color: #f4f4f4;
      border: none;
      border-radius: 20px 20px !important;
      outline-style: none;
      &:hover {
        background-color: rgb(227, 227, 227);
      }
    }
    p {
      position: absolute;
      left: 10px;
      top: 12px;
      z-index: 10;
      width: 80px;
      font-size: 14px;
      color: grey;
    }
  }
  .info-legend {
    margin-bottom: 20px;

    ul {
      padding: 0;
    }

    ul li {
      text-align: left;
      list-style-type: none;
      font-size: 12px;
      margin-bottom: 5px;
    }

    img {
      height: 25px;
      padding-right: 25px;
    }
  }
  #inner-map {
    margin-right: 0;
    margin-left: 0px;
    width: calc(100% - 30px);

    // canvas {
    // 	//height: 100% !important;
    // 	//width: 100% !important;
    // }

    @media screen and (max-width: 767px) {
      margin-left: -15px;
      width: calc(100% - 15px);
    }
  }
}

// Map detail update
.mapview {
  .detail {
    .map-detail-items {
      padding: 15px 15px 0 15px;
      .scroller {
        padding-right: 0;

        .nav.nav-tabs {
          white-space: nowrap;
          display: block;
          overflow: auto;
          padding-bottom: 15px;
          -webkit-overflow-scrolling: touch;
          //scroll-snap-type: y mandatory;

          a {
            display: inline-block;
            //scroll-snap-align: start;
            border: 0;
            font-size: 13px;
            padding: 0;

            & + a {
              margin-left: 20px;
              color: #444;
            }

            &.active {
              font-weight: bold;
              color: #444;
            }
          }
        }
      }
    }

    .card-header {
      padding-top: 6px;
      padding-bottom: 6px;
      .color {
        width: 16px;
        min-width: 16px;
        height: 16px;
        display: block;
        border-radius: 50%;
        margin-right: 15px;
      }

      .header-item {
        > div {
          margin-left: 0;

          small {
            color: #ababab;
            font-weight: normal;
          }
        }
      }

      .external {
        position: relative;
        cursor: pointer;
        top: 2px;
        svg {
          width: 16px;
          font-size: 16px;
          path {
            fill: #ababab;
          }
        }

        &:hover {
          svg {
            path {
              fill: #65baf2;
            }
          }
        }
      }

      .minimize {
        position: relative;
        cursor: pointer;
        margin-right: 10px;
        top: 2px;
        svg {
          path {
            fill: #ababab;
          }
        }
        &:hover {
          svg {
            path {
              fill: #65baf2;
            }
          }
        }
      }

      .close {
        opacity: 1 !important;
        svg {
          g {
            stroke: #ababab;
          }
        }
        &:hover {
          svg {
            g {
              stroke: #65baf2;
            }
          }
        }
      }
    }

    .card-body {
      padding: 0;
      margin-right: 0;

      .rdt_Table {
        .rdt_TableHeadRow {
          border-bottom: 1px solid #e9e9e9;
          .rdt_TableCol {
            background-color: white;
            color: #444;
            padding-left: 6px;
            padding-right: 6px;
            div {
              color: #444 !important;
              font-size: 12px;
              font-weight: 600;
            }
          }
        }

        .rdt_TableRow {
          border-bottom: 1px solid #e9e9e9;
          overflow: hidden;
        }

        .rdt_TableCell {
          padding-left: 6px;
          padding-right: 6px;

          i {
            margin-left: 0;
            font-size: 14px;
          }

          div {
            line-height: 130%;
            text-align: center;
          }
        }

        .rdt_TableCol_Sortable {
          > div {
            line-height: 130%;
          }
        }

        .rdt_ExpanderRow {
          overflow: hidden;

          .project-details-map {
            padding: 0 15px;
            align-items: center;
            border-bottom: 1px solid #e9e9e9;
            padding-bottom: 10px;

            p {
              font-size: 13px;
              padding-right: 10px;
              padding-left: 0;
              padding-top: 0;

              strong {
                font-weight: 600;
              }
            }

            button {
              margin-right: 10px;
              padding: 5px 10px;
              margin-bottom: 0;
              color: #777;
              font-size: 12px;

              svg {
                margin-right: 5px;
                font-size: 1.125em;
                path {
                  fill: #777;
                }

                &[data-icon="search-plus"] {
                  position: relative;
                  top: 1px;
                }
              }

              &:focus {
                outline: none;
                box-shadow: none;
              }
            }

            > *:last-child {
              margin-right: 0;
            }
          }

          .table-wrapper {
            @media screen and (max-width: 767px) {
              margin: 20px 0;
            }

            .col-sm-3 {
              flex: 0 0 25%;
              max-width: 25%;
              width: 25%;
            }

            table {
              tr {
                &:nth-child(2n + 1) {
                  td {
                    background-color: #f7f7f7;
                  }
                }

                &:nth-child(2n) {
                  td {
                    background-color: #f1f1f1;
                  }
                }
              }
            }
          }

          .images {
            @media screen and (max-width: 767px) {
              margin-top: -5px;
              margin-bottom: 25px;
            }
            h5 {
              font-weight: 600;
              font-size: 13px;
              margin-bottom: 15px;
              @media screen and (max-width: 767px) {
                margin-bottom: 5px;
              }
            }
          }
        }
      }

      .rdt_Pagination {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

.filter-map-view-number {
  padding: 20px 0px 5px 20px;

  p {
    margin: 0;
  }
}

.mapview {
  .city-selector-container {

    .city-selector {
      position: absolute;
      border: none;
      background-color: rgb(244, 244, 244);
      left: 435px;
      padding: 8px;
      width: -moz-fit-content;
      width: 11.5rem;
      top: 17px;
      z-index: 10;
      text-align: left;
      padding-right: 0;
      outline: none;
      border-radius: 30px;
      height: 42px;
      border-right: 8px solid transparent;
      cursor: pointer;

      &:focus-visible {
        border-right: 8px solid transparent;
        border-top: none;
        border-left: none;
        border-bottom: none;
        outline: none;
      }

      &:hover {
        background-color: #e3e3e3 !important;
      }
    }
  }

  .popup-content-map {
    input {
      border: none;
      background-color: #dfdfdf;
      border-radius: 8px;
      padding: 3px;
      padding-left: 10px;
      font-family: "Montserrat", sans-serif;
    }

    button {
      border: none;
      border-radius: 8px;
      margin: 5px;
      padding: 2px 10px 2px 10px;
    }

    .accept{
      background-color: #7dd173;
      color:white
    }

    .accept:hover {
      color: #7dd173;
      background-color: white;
    }

    .exit {
      color:white;
      background-color: #d17373;
    }
    .exit:hover {
        color: #d17373;
        background-color: white;
    }
  }

  .ol-popup {
    width: 310px !important;
    padding: 10px;
  }
}

.zoom-display {
  p {
    position: absolute;
    left: 320px;
    top: 75px;
    z-index: 10;
    width: 80px;
    font-size: 14px;
    color: grey;
  }
}

.loader-container-map-layer {
    position: absolute;
    top: 18px;
    z-index: 10;
    left: 393px;

    .loader {
      border: 8px solid #7dd173;
      border-top: 8px solid white;
    }
}

.map-information-container {
    position: absolute;
    bottom: 0px;
    z-index: 1;
    font-size: 11px;
    width: 430px;

    @media screen and (max-width: 767px) {
      bottom: 20px;
      width: 85%;
      max-width: 430px;
    }

    p {
      margin-top: 9px;
    }

    a {
      color: #65baf2;
    }

    a:hover {
      color: #4885ad;
    }
}

.ol-zoom {
  background: none !important;
  top: 0.7em !important;

  .ol-zoom-in {
    border-radius: 30px 30px 0 0 !important;
  }

  .ol-zoom-out {
    border-radius: 0 0 30px 30px !important;
  }

  button {
    margin: 4px !important;
    color: black !important;
    font-size: 1.4rem !important;
    background-color: white !important;

    &:hover {
      background-color: #e3e3e3 !important;
    }
  }
}

.ol-geocoder {
  .ol-control {
    background: none;

    &:hover {
      background: none;
    }
  }

  pointer-events: auto;
  margin-top: 15px;
  margin-left: 625px;
  z-index: 2000;
  
  .gcd-gl-btn {
    display: none;
  }

  input {
    font-size: 14px;
    padding: 10px;
    border: none;
    border-radius: 30px;
    height: 42px;
    background-color: rgb(244, 244, 244);
  }

  input:focus {
    outline: none
  }

  input:hover {
    outline: none;
  }


  .gcd-gl-result {
    padding-top: 30px;
    font-size: 13px;
    padding-left: 2px;

    a {
      color: black;
      text-decoration: none;
    }

    li {
      padding: 10px 14px;
      max-width: 400px;
      background-color: rgba(255, 255, 255, 0.75);
      border-radius: 0px;
      max-width: 500px;
      display: inline-block;
      border-bottom: 1px solid #ccc;
      border-top: 1px solid #fff;
      cursor: pointer;

      &:hover {
        background-color: rgba(255, 255, 255, 0.85);
      }

    }
  }
}
#forgot {
  height: 100%;
  background-color: #f2f2f2;
  overflow: hidden;

  @media screen and (max-width: 767px) {
    background-color: transparent;
    height: auto;
  }
  .container {
    position: relative;
    z-index: 3;
    height: 100%;
    .row {
      height: 100% !important;
      .col-md-* {
        height: 100%;
      }
    }
  }

  .warning {
    color: red;
    text-align: center;
    font-size: 12px;
    display: block;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .card {
    max-width: 390px;
    min-width: 390px;
    border-radius: 0;
    margin-top: 246px;
    background: #201746;
    border: 1px solid #2E6B99;
    color: #fff;

    .card-title {
      font-weight: 700;
      font-size: 32px;
      line-height: 39px;
      text-align: center;
      letter-spacing: -0.28px;
      color: #FFFFFF;
      margin-top: 27px;
    }
  }

  .card-body {
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 34px;
    padding-right: 34px;

    .message {
      text-align: center;
      font-size: 20px;
    }

    @media screen and (max-width: 767px) {
      padding: 20px;
    }

    .criterias {
      padding-bottom: 1.6rem;
      font-size: 0.9rem;

      .criterias-title {
        margin-bottom: 4px;
      }

      ul {
        margin-bottom: 0px;
        padding-left: 16px;

        li {
          span {
            font-weight: 700;
          }
        }
      }
    }

    .form-row {
      margin-left: 0px;
      margin-right: 0px;

      .form-check {
        text-indent: 10px;
      }

      .lost {
        font-size: 14px;
        line-height: 25px;
      }
    }

    .card-bottom {
      margin-top: 40px;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      letter-spacing: -0.28px;
      color: #FFA39B;

      a {
        text-decoration: none;

        &.more-info-button {
          display: inline-block;
          margin-top: 10px;
          line-height: 130%;
          color: #FFA39B;
          transition: all 400ms ease 0s;
          border-bottom: 1px solid transparent;

          &:hover {
            border-bottom: 1px solid #99d7d8;
          }
        }
      }
    }

    i {
      font-style: normal;
      line-height: 130%;
      display: block;
      &:hover {
        color: #FF785D;
      }
    }

    .form-group {
      ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
       color: #fff;
        }
        
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: #fff;
        }
        
        ::-ms-input-placeholder { /* Microsoft Edge */
          color: #fff;
        }
      input[type="text"],
      input[type="password"] {
        background: #FF785D;
        box-shadow: 0px 2px 10px #C73012;
        border-radius: 28px;
        border: 0;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #FFFFFF!important;

        &:-webkit-autofill {
          -webkit-box-shadow: 0 0 0px 1000px #FF785D inset !important;
        }
      }
    }

    .btn {
      background-color: #68CED9;
      &:focus,
      active:focus {
        outline: none;
        box-shadow: none;
        color: white;
      }
    }
  }
}

.forgot-page {
  width: 100%;
  height: 100%;
  @media screen and (max-width: 767px) {
    background-image: url("/mobile_home_bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1000px auto;
    min-height: 100%;
    height: auto;
  }

  #forgot-bg {
    width: 80%;
    height: 100%;
    position: absolute;
    z-index: 2;
  }

  .map {
    width: 85%;
    height: 100%;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1;

    @media screen and (max-width: 767px) {
      display: none;
    }

    .leaflet-container {
      height: 100%;
      width: 100%;
    }
  }
}

.map-border,
.map-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  -webkit-filter: drop-shadow(11px 2px 16px rgba(50, 50, 0, 0.5));
  filter: drop-shadow(11px 2px 16px rgba(50, 50, 0, 0.5));

  @media screen and (max-width: 767px) {
    display: none;
  }

  .content {
    width: 100%;
    height: 100%;
    clip-path: polygon(0 0, 88% 0, 66% 100%, 0% 100%);
    background-color: #201746;
    background-image: url("/background_shape.png");
    background-size: 138%;
    background-position: center left;
  }
}

.map-border {
  left: 12px;
  position: fixed;
  .content {
    background: #444444;
  }
}
.forgot-page {
  .logo {
    margin-top: 110px;
    display: inline-block;
    border: 1px solid #2E6B99;
    height: fit-content;
    background-color: #201746;
    padding: 10px 17px;

    svg {
      height: 115px !important;
      width: fit-content;
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 25px;
      margin-top: 40px;
      svg {
        width: 170px !important;
        height: auto !important;
      }
    }
  }
}

.place {
  text-transform: uppercase;
  font-size: 21px;
  font-weight: bold;

  @media screen and (max-width: 767px) {
    font-size: 17px;
  }
}

.ums-logo,
.utu-logo {
  height: 50px;
}

.bottom-logos {
  bottom: 30px;
  left: 40px;
  z-index: 1000;
  position: absolute;

  @media screen and (max-width: 767px) {
    position: static;
    padding-bottom: 40px;
    padding-top: 40px;
    text-align: left;
    padding-left: 0;
    text-align: center;

    svg {
      height: 40px;
      width: auto;
      & + svg {
        margin-left: 15px;
      }
    }
  }
}

// Modal update
body {
  &.modal-open {
    .modal {
      display: flex !important;
      height: 100%;

      .modal-dialog {
        margin: auto;
      }
    }
  }

  .modal-backdrop {
    background-color: white;
    z-index: 6040;

    &.show {
      opacity: 0.3;
    }
  }

  .modal {
    top: 50%;
    transform: translateY(-50%);
    z-index: 6050;
    .modal-content {
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
      border: 0;
    }

    .modal-header {
      font-size: 16px;
      font-weight: 600;
    }

    .modal-footer {
		padding: 12px 31px;
      button {
        color: white;
        min-width: 200px;
        font-weight: 600 !important;
        margin: 0;

        &:focus,
        &:active {
          color: white;
          box-shadow: none !important;
          outline: 0;
          font-weight: 600 !important;
        }
      }
    }

    // Other info modal
    &.other-info-modal {
      .modal-header {
        border: 0;
        flex-wrap: wrap;

        .logo-container {
          margin-top: 20px;
          width: 100%;
          height: 70px;
          text-align: center;
          margin-bottom: 30px;
          @media screen and (max-width: 991px) {
            height: 50px;
          }
          svg {
            height: 70px;
            width: auto;
            @media screen and (max-width: 991px) {
              height: 50px;
            }
          }
        }

        .modal-title {
          text-align: center;
          display: block;
          width: 100%;
          font-size: 17px;
          font-weight: 600;
          @media screen and (max-width: 991px) {
            font-size: 16px;
          }

          @media screen and (max-width: 991px) {
            font-size: 15px;
          }
        }
        .close {
          display: none;
        }
      }

      .modal-body {
        padding-left: 70px;
        padding-right: 70px;
        padding-top: 10px;

        @media screen and (max-width: 991px) {
          padding-left: 15px;
          padding-right: 15px;
          padding-top: 0;
        }

        p {
          text-align: center;
          line-height: 200%;
          font-size: 16px;
          font-weight: 500;
          @media screen and (max-width: 991px) {
            font-size: 15px;
            line-height: 160%;
            margin-bottom: 10px;
          }
          @media screen and (max-width: 991px) {
            font-size: 14px;
          }
          strong {
            font-weight: 700;
          }

          span {
            color: #ff006f;
          }
        }
      }

      .modal-footer {
        border: 0;
        padding-top: 0;
        padding-bottom: 30px;
        text-align: center;
        justify-content: center;

        button {
          color: white;
          min-width: 200px;

          &:focus,
          &:active {
            color: white;
            box-shadow: none !important;
            outline: 0;
          }
        }
      }
    }
  }
}

.show-pass {
  position: relative;
  left: 287px;
  top: -36px;
  color: #e3e3e3; 
  
  @media screen and (max-width: 767px) {
    top: 104px;
  }
}

//loader
.forgot-lds-ellipsis {
  display: inline-block;
  position: relative;
  margin: 10px;
  margin-right: 90px;
}
.forgot-lds-ellipsis div {
  position: absolute;
  top: 0px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.forgot-lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.forgot-lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.forgot-lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.forgot-lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

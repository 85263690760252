.detailed-container-thirdparty {
    width: 100%;
    margin-bottom: 15px;

    .tabs-interface-section {
        .nav-tabs {
            width: 80%;
            border-bottom: 0;

            .nav-link {
                background-color: white;
                border-radius: 0;
                padding: 0;
                height: 45px;
                display: flex;
                align-items: center;
                margin-right: 5px;
                width: 80%;
                padding-left: 17px;

                img {
                    height: 40px !important;
                }
            }

            .nav-link.active,
            .nav-item.show .nav-link {
                border-radius: 0;
                background-color: #F5F5F5;
                border-color: #F5F5F5;
            }
        }

        .section-content {
            .interface-body.container-fluid {
                background-color: #F5F5F5;
                padding-top: 0;

                .left-container {
                    display: flex;
                    flex-direction: row;
                    padding: 0;

                    .icons-list {
                        width: 70px;
                        text-align: center;
                        padding-top: 10px;
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;

                        .bottom-icons {
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            div {
                                margin-top: 10px;
                                background-color: #C4C4C4;
                                border-radius: 50%;
                                height: 40px;
                                width: 40px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                box-shadow: 0px 2px 10px #C4C4C4;

                                &:hover {
                                    background-color: red;
                                    cursor: pointer;
                                    transition: 0.2s all ease;
                                }

                                svg {
                                    fill: white;
                                    color: white;
                                    font-size: 20px;
                                }
                            }

                            div.active {
                                background-color: red;
                            }
                        }

                        .clicked-icon {
                            svg {
                                color: #C4C4C4;
                                font-size: 30px;

                            }
                        }
                    }

                    .left-sidebar {
                        background-color: white;
                        width: inherit;
                        margin-right: 10px;
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;
                        .sidebar-bottom {
                            text-align: center;
                            padding-bottom: 32px;
                        }
                    }
                }

                .right-container {
                    padding: 0;
                    margin-top: 5px;
                }
            }
        }
    }
}
.pharos-container {
    color: $white;
    margin: auto;

    .pharos-logo {
        text-align: center;
        margin: 40px 0;
    }

    .login-container {
        max-width: 350px;
        margin: auto;
        display: flex;
        flex-direction: column;
        text-align: center;
        background: #201746;
        border: 1px solid #2E6B99;
        padding: 20px;
    }

    h1.login-title {
        color: $white;
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 39px;
        text-align: center;
        letter-spacing: -0.28px;
        padding-bottom: 40px;
    }

    .form-data {
        ::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #fff;
        }

        :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #fff;
        }

        ::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #fff;
        }

        input[type="text"],
        input[type="password"] {

            &:-webkit-autofill {
                -webkit-box-shadow: 0 0 0px 1000px #FF785D inset;
                box-shadow: 0 0 0px 1000px #FF785D inset;
            }
        }

        .login-input {
            background: #FF785D;
            box-shadow: 0px 2px 10px #C73012;
            border-radius: 28px;
            border: 0;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            width: 100%;
            padding: 10px;
            margin-bottom: 20px;
        }

        .btn-submit {
            background-color: #68CED9;
            width: 100%;
            color: #ffffff;
            font-weight: bold;
            font-size: 14px;
            cursor: pointer;
            padding: 10px;
            border: 0;
            margin-top: 20px;
        }
    }
}

ul .boxes {
    color: $white;
}

.pharos-dashboard-boxes {
    margin: 20px 5%;
    .dasboard-container-row {
        .col-kaptalan {
            height: 400px;
            overflow: scroll;
            padding: 20px;
            font-size: 12px;
          //  max-width: 450px;
            border-radius: 8px;
            .pharos-category-title {
                font-size: 20px;
                font-weight: 700;
                display: flex;
                min-height: 0;
                color: #444444;
                text-align: center;
                justify-content: center;
            }
            .thirdparty-description {
                white-space: pre-line;  
                vertical-align: bottom;
            }
        }
    }
}